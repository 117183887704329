.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 30px;
}

.title h2{
    font-size: 18px;
    margin-bottom: 10px; 
}
.title p{
    font-size: 35px;
}

.title-heading {
    position: relative;
    display: inline-block;
  }
  
  .title-heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px; /* Adjust the height of the underline as needed */
    background-color: orange; /* Change the color of the underline */
    display: block;
  }
  