@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;

    
  }


  body{
    background: #F5F5F5;
   
  }

  .mybutton{
 
    font-size: 15px;
    font-weight: 500;
    color: black;
    padding: 10px 20px;
    border: none; /* Add this line to remove the border */
   background-color:  #FF9913;
   border-radius: 5px;
  }

  .mycontainer{
    padding-left: 20%;
    padding-right: 20%;
  }
  .mycontainer-2{
    padding-left: 5%;
    padding-right: 5%;
  }
  

  .content-container {
    margin-top: 0; /* Adjust this value based on the height of your navbar */
  }