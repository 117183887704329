
.contactus-2{
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 100px;
    padding-bottom: 80px;
}
.contactus-2-top-1,.contactus-2-top-2,.contactus-2-top-3 {
    border: 1px solid #ccc; /* Light gray border */
    padding: 30px; /* Optional: Add padding for better spacing */
    flex-basis: 30%;
}
.contactus-2-top-1 img, .contactus-2-top-2 img, .contactus-2-top-3 img{
     width: 80px;
     height: 80px;
}

.contactus-2-top-1 h6,.contactus-2-top-2 h6,.contactus-2-top-3 h6{
   font-size: 16px;
   padding-top: 10px;
}

.contactus-2-top-1 p,.contactus-2-top-2 p,.contactus-2-top-3 p{
    font-size: 18px;
    
 }
 .contactus-2-top-2 a{
  text-decoration: none;
  color: black;
  padding-top: 0;
  
 }
 .para{
  padding-top: 20px;
    
 }
 .contactus-2-top{
display: flex;
justify-content: space-between;
 }
 .contactus-2-top-3 a{
  text-decoration: none;
  color: black;
 }


 .contactus-2-top > div {
    transition: transform 0.3s ease, border-color 0.3s ease, border-width 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow to the transition */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.contactus-2-top > div:hover {
    transform: translateY(-20px); /* Increase the movement from bottom to top */
    border-color: orange; /* Change border color on hover */
    border-width: 2px; /* Increase border width on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase box shadow on hover */
}

.contactus-2-top > div:hover h6 {
    text-decoration: underline; /* Underline h6 on hover */
    animation: moveLeft 0.5s forwards; /* Move h6 from right to left */
}

@keyframes moveLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.contactus-2-bottom-1 h6{
   font-size: 18px;
   position: relative;
    display: inline-block;
    padding-top: 90px;
}

.contactus-2-bottom-1 h6::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px; /* Adjust the height of the underline as needed */
    background-color: orange; /* Change the color of the underline */
    display: block;
 }

 .contactus-2-bottom-1 h2{
    font-size: 35px;
    padding-top: 10px;
    width: 400px;
    font-weight: 400;
 }


 /*form css*/

 form {
   
    border-radius: 5px;
    padding: 50px;
    margin-top: 90px;
    background-color: #fff;
  
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .contactus-2-bottom-2 form input, .contactus-2-bottom-2 form textarea {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    resize: vertical;
  }
  
  textarea {
    resize: vertical;
  }
  
  .contactus-2-bottom-2 form button{
    background-color: #FF9913;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }
  
  .contactus-2-bottom-2  form button:hover {
    background-color: #0056b3;
  }
  .contactus-2-bottom{
    display: flex;
  
    justify-content: space-between;
  }

  .contactus-2-bottom-1,
.contactus-2-bottom-2 {
    flex-basis: 46%;
    flex: 1;
}



@media (max-width: 1700px) {
  .contactus-2 {
    margin-left: 10%;
    margin-right: 10%;
}
  .contactus-2-top{
    width: 100%;
    
  }
  .contactus-2-top-1, .contactus-2-top-2, .contactus-2-top-3{

margin-right: 30px;
  }
  .contactus-2-top-1 img,.contactus-2-top-2 img,.contactus-2-top-3 img{
    width: 80px;
    height: 80px;
  }
  .contactus-2-top-1 h6,.contactus-2-top-1 h6,.contactus-2-top-1 h6{
    width: 100%;
  }
  .contactus-2-top-1 p,.contactus-2-top-1 p,.contactus-2-top-1 p{
    width: 100%;
  }
}


/* Media Query for Medium Screens */
@media (max-width: 1024px) {
  .contactus-2 {
      margin-left: 10%;
      margin-right: 10%;
      padding-left: 25px;
      margin-bottom: 0px;
  }

  .contactus-2-top {
      flex-direction: column;
      align-items: center;

  }

  .contactus-2-top-1, .contactus-2-top-2, .contactus-2-top-3 {
      flex-basis: 100%;
      margin-bottom: 40px;
      width: 500px;
      max-width: 100%;
      
  }
  .contactus-2-top-1 img, .contactus-2-top-2 img, .contactus-2-top-3 img{
    width: 80px;
    height: 80px;
}

  .contactus-2-bottom {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
  }

  .contactus-2-bottom-1, .contactus-2-bottom-2 {
      flex-basis: 100%;
      width: 100%;
  }

  .contactus-2-bottom-1 h2 {
      width: 100%;
      height: auto;
 
}


}
@media (max-width: 650px) {
  .contactus-2 {
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 25px;
  padding-bottom: 60px;
  padding-top: 80px;
}
.contactus-2-bottom-2 {
  margin-right: 25px;
  
}
.contactus-2-bottom-1 h6{
  
   padding-top: 30px;
}

.contactus-2-top-1 img, .contactus-2-top-2 img, .contactus-2-top-3 img{
  width: 80px;
  height: 80px;
}
form {
   
  padding: 30px;
  margin-top: 50px;
}

.contactus-2-bottom-2 form input, .contactus-2-bottom-2 form textarea {
 
  padding: 20px;
  margin-bottom: 20px;
  
}

}


