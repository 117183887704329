
.projectsdetail-2{
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 70px;
}


.projectsdetail-2-top ul li{
    display: inline-block;
    padding-right: 70px;
    padding-bottom: 40px;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    width: auto; /* Set width to auto */
}

.projectsdetail-2-bottom img{
    width: 360px;
    height: 275px;
    margin-bottom: 120px;
}

.projectsdetail-2-bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.projectsdetail-2-bottom-content{
    flex-basis: 45%;
}
.projectsdetail-2-bottom-content h5{
    font-size: 30px;
    padding: 30px;
    background-color: black;
    color: white;
}

.projectsdetail-2-bottom-content {
    position: relative; /* Set the container as a positioning context for absolute positioning */
  }
  
  .projectsdetail-2-bottom-content h5 {
    position: absolute;
     left: 35px;
    bottom: 70px; 
  }

  .projectsdetail-2-bottom-content:hover h5 {
    background-color:  #FF9913;
    color: black; /* Adjust text color */
}


.animation-effect {
    /* Define your animation properties here */
    animation-name: fadeIn; /* Assuming fadeIn is your animation */
    animation-duration: 1s; /* Adjust duration as needed */
    /* Add any other animation properties */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .projectsdetail-2-top ul li span {
    position: relative;
    display: inline-block;
}

.projectsdetail-2-top ul li span::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 4px;
    background-color: orange;
    transition: width 0.3s ease;
}

.projectsdetail-2-top ul li:hover span::after {
    width: 100%;
}

.projectsdetail-2-top ul li .orange-underlined::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%; /* Set to 100% to show full underline */
    height: 4px;
    background-color: orange;
    transition: width 0.3s ease;
}


@media (max-width: 1300px) {
  projectsdetail-2 {
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 70px;
}

.projectsdetail-2-top ul li {
    display: inline-block;
    padding-right: 50px;
    padding-bottom: 40px;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    width: auto; /* Set width to auto */
}
.projectsdetail-2-bottom img{
  width: 360px ;
  height: 275px;
  margin-bottom: 120px;
}

.projectsdetail-2-bottom{
  display: flex;
  justify-content: center;
}
.projectsdetail-2-bottom-content{
  flex-basis: 45%;
}
.projectsdetail-2-bottom-content h5{
  font-size: 30px;
  padding: 30px;
  background-color: black;
  color: white;
}

}

@media (max-width: 630px) {

  .projectsdetail-2 {
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  .projectsdetail-2-top ul li {
    display: inline-block;
    padding-right: 50px;
    padding-bottom: 40px;
    font-size: 15px;
    
}
.projectsdetail-2-top ul {
  display: flex;
 align-items: center;
 justify-content: center;
 margin-left: 30px;
}
}